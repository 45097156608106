<template>
  <div>
    <el-button size="small" @click="changePage">新增消息模板</el-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    changePage() {
      this.$router.push({
        path: '/infoManage/message/templateChange',
        query: {
          type: 'add'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
